/**
 * Copied and modified from Kazaam's `initDatadog.ts` file
 */

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import {
  APP_VERSION,
  ENV_NAME,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
} from '../env';

/**
 * @type {RegExp[]}
 */
const ERROR_BLOCKLIST = [/^console error: Amplitude Logger.*?Failed to fetch$/];

/**
 * STOP!
 *
 * Are you making code changes to this config?
 *
 * Please note that by default this config will NOT get loaded locally
 * or in garden, so if you make a mistake that breaks the app it WON'T
 * manifest until this code hits prod.
 *
 * Before making or approving changes, make sure they have been tested.
 *
 * For more information about how to do that, visit the relevant
 * Confluence file (https://podium.atlassian.net/wiki/spaces/EN/pages/2588180497/Testing+Datadog).
 */
export default function initDatadog(initOpts = {}) {
  if (DATADOG_CLIENT_TOKEN && DATADOG_APPLICATION_ID) {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'mithrandir',
      version: APP_VERSION,
      env: ENV_NAME,
      sampleRate: 10,
      sessionReplaySampleRate: 0,
      trackInteractions: false,
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: onBeforeSend,
      ...initOpts,
    });

    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      forwardErrorsToLogs: false,
      service: 'mithrandir',
      env: ENV_NAME,
      sampleRate: 100,
    });
  }
}

/**
 * Process an event before it is sent to Datadog.
 *
 * See: https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-and-control-rum-data
 *
 * @param {import('@datadog/browser-rum').RumEvent} event Datadog event representing the error
 * @returns {boolean} whether to keep or discard the event
 */
function onBeforeSend(event) {
  switch (event.type) {
    case 'error':
      return processErrorEvent(event);
    default:
      // Keep all other events
      return true;
  }
}

/**
 * Analyze an event related to an error and determine whether to keep or discard it.
 *
 * @param {import('@datadog/browser-rum').RumErrorEvent} event Datadog event representing the error
 * @returns {boolean} whether to keep or discard the event
 */
function processErrorEvent(event) {
  for (const blocklistItem of ERROR_BLOCKLIST) {
    if (event.error.message.match(blocklistItem)) {
      return false;
    }
  }

  return true;
}
