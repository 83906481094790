import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export function isValidEmailOrPhone(emailOrPhone) {
  return /^(\S+@\S+\.\S+|[-\d+(). ]+|p-\d+|p-\S+)$/.test(emailOrPhone);
}

function formatPhone(phone) {
  try {
    const trimmedNumber = phone.replace(/^0+/, '');

    return PhoneNumberUtil.getInstance().format(
      PhoneNumberUtil.getInstance().parse(trimmedNumber, 'US'),
      PhoneNumberFormat.E164,
    );
  } catch (e) {
    return phone;
  }
}

function isEmail(username) {
  return (
    username == null || username.includes('@') || /[a-zA-Z]/.test(username)
  );
}

export function formatIfPhone(username) {
  return isEmail(username) ? username : formatPhone(username);
}
