import React from 'react';
import { colors, IconAlertCircle } from '@podiumhq/podium-ui';
import { BannerIcon, Banner } from './styles';

export default function InvalidLoginBanner({ show, message }) {
  return (
    <Banner show={show}>
      <BannerIcon>
        <IconAlertCircle fill={colors.poppyRed} />
      </BannerIcon>
      {message}
    </Banner>
  );
}
