import styled from 'styled-components';

export const AdContainer = styled.div(
  ({ fadeIn }) => `
  display: none;
  width: 100%;

  @media (min-width: 1024px) {
    background-color: #EEECEA;
    display: flex;
    height: 100vh
    width: 900px;

    iframe {
      width: 100%;
      height: 100%;
      border: none;

      transition: opacity 0.3s;
      opacity: 0;
      ${fadeIn && ` opacity: 1; `}
    }
  }
`,
);
