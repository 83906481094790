import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

export const ButtonHolder = styled.div`
  width: 100%;
`;

export const FormInputContainer = styled.div`
  #verificationCode + div {
    position: absolute;
  }
`;

export const HelpText = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
  padding: 0 40px;
  text-align: center;
`;

export const Input = styled.div`
  padding-bottom: 32px;
  position: relative;
`;

export const LoginContainer = styled.div`
  // Anchor the sign-in form in the middle of the page so that it is aligned
  // with the ad on the right side. Mobile doesn't have the ad, so do not center
  // for smaller screens.
  @media (min-width: 1024px) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Logo = styled.div`
  margin: 0 auto 72px;
  text-align: center;
  transition: all 200ms 200ms;
  white-space: nowrap;
`;

export const PolicyText = styled.div`
  font-size: 10px;
  font-weight: normal;
  margin-top: 30px;
  padding: 0 10px;
  text-align: center;
  color: ${colors.steel};
`;

export const Divider = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  background: #d9d9da;
  width: 100%;
  height: 1px;
`;
