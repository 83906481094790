export function fingerprintHeaders() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const width = window.screen.availWidth;
  const height = window.screen.availHeight;
  const userLang = navigator.language || navigator.userLanguage;

  const plugins = [];
  for (let i = 0; i < navigator.plugins.length; i += 1) {
    plugins.push(navigator.plugins[i].name);
  }

  return {
    'X-Client-Timezone': timezone,
    'X-Client-Screen-Width': width,
    'X-Client-Screen-Height': height,
    'X-Client-Lang': userLang,
    'X-Client-Plugins': plugins.join(','),
  };
}
