import React, { useEffect, useState } from 'react';
import { StatusIcon } from '@podiumhq/podium-ui';
import { useMutation } from '@apollo/client';
import { Header, Text, IconHolder, ScreenWrapper, DoneText } from './styles';
import { ContentContainer } from '../App/styles';
import {
  USER_MOCK_APPROVE,
  USER_MOCK_DENY,
  MOCK_USER_APPROVAL_APPROVE_DENY_SUCCESSFULLY_TYPE,
  MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_INVALID_TYPE,
  MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_EXPIRED,
  MOCK_USER_APPROVAL_APPROVE_DENY_INVALID_STATE_TRANSITION,
} from '../../graphql/mutations';
import { InvalidLoginBanner } from '../index';

const TOKEN_PARAM = 't';
const ICON_PENDING = { status: 'pending' };
const ICON_SUCCESS = { status: 'success', countdown: 0.8 };
const ICON_FAIL = { status: 'fail', countdown: 0.8 };

const INVALID_TOKEN_MESSAGE = 'Mock request not found.';
const EXPIRED_TOKEN_MESSAGE = 'Mock request has already expired.';
const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again.';
const INVALID_TRANSITION_ERROR_MESSAGE =
  'The mock request has already been processed.';

export default function MockUserApproval({ approve }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [iconConfig, setIconConfig] = useState(ICON_PENDING);

  const handleSuccess = (data) => {
    const responseType =
      data.approveMockRequest?.__typename || data.denyMockRequest?.__typename;

    switch (responseType) {
      case MOCK_USER_APPROVAL_APPROVE_DENY_SUCCESSFULLY_TYPE:
        setIconConfig(ICON_SUCCESS);
        break;
      case MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_INVALID_TYPE:
        setErrorMessage(INVALID_TOKEN_MESSAGE);
        setIconConfig(ICON_FAIL);
        break;
      case MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_EXPIRED:
        setErrorMessage(EXPIRED_TOKEN_MESSAGE);
        setIconConfig(ICON_FAIL);
        break;
      case MOCK_USER_APPROVAL_APPROVE_DENY_INVALID_STATE_TRANSITION:
        setErrorMessage(INVALID_TRANSITION_ERROR_MESSAGE);
        setIconConfig(ICON_FAIL);
        break;
      default:
        setErrorMessage(GENERIC_ERROR_MESSAGE);
        setIconConfig(ICON_FAIL);
    }
  };

  const handleError = () => {
    setErrorMessage(GENERIC_ERROR_MESSAGE);
    setIconConfig(ICON_FAIL);
  };

  const [approveMock] = useMutation(USER_MOCK_APPROVE, {
    onCompleted: handleSuccess,
    onError: handleError,
  });

  const [denyMock] = useMutation(USER_MOCK_DENY, {
    onCompleted: handleSuccess,
    onError: handleError,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get(TOKEN_PARAM);

    if (!token) return;

    const mutationPayload = {
      variables: {
        mockRequestToken: token,
      },
    };

    if (approve) {
      approveMock(mutationPayload);
    } else {
      denyMock(mutationPayload);
    }
  }, [approve, approveMock, denyMock]);

  return (
    <ContentContainer>
      <ScreenWrapper>
        <IconHolder>
          <StatusIcon
            status={iconConfig.status}
            countdown={iconConfig.countdown}
          />
        </IconHolder>
        <Header>Hang tight for a moment.</Header>
        <Text>
          We're {approve ? 'approving' : 'denying'} the mock request...
        </Text>
        {iconConfig === ICON_SUCCESS && <DoneText>done!</DoneText>}
        <InvalidLoginBanner show={!!errorMessage} message={errorMessage} />
      </ScreenWrapper>
    </ContentContainer>
  );
}
