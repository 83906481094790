import React, { useState } from 'react';
import { AdContainer } from './styles';

const LOGIN_PAGE_ADS_URL = 'https://cms.podium.com/login-module-2';

export default function LoginPageAds() {
  const [fadeIn, setFadeIn] = useState(false);

  const onIframeLoad = () => {
    setTimeout(() => setFadeIn(true), 0);
  };

  if (window.location.href.includes('oauth')) return null;

  return (
    <AdContainer fadeIn={fadeIn}>
      <iframe
        title="login-page-ads"
        src={LOGIN_PAGE_ADS_URL}
        onLoad={onIframeLoad}
      />
    </AdContainer>
  );
}
