import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { HeroElementContainer } from '@podiumhq/first-meaningful-paint';
import { AppWrapper, SidePanelWrapper, WithAdWrapper } from './styles';
import paths from '../../lib/paths';
import { performRedirect } from '../../lib/redirect';
import { hasAuthToken, removeCookies } from '../../lib/cookies';
import { initThirdPartyScripts } from '../../lib/third-party-scripts';
import { inIframe } from '../../lib/helpers';
import {
  handleTitleAndFavicon,
  useWhiteLabelData,
} from '../../lib/white-label';
import ProtectedRoute from '../ProtectedRoute';
import PasswordLogin from '../PasswordLogin';
import VerificationCodeLogin from '../VerificationCodeLogin';
import SidePanel from '../SidePanel';
import LoginPageAds from '../LoginPageAds';
import MockUserApproval from '../MockUserApproval';

const PasswordlessLogin = lazy(() => import('../PasswordlessLogin'));
const RequestCode = lazy(() => import('../RequestCode'));
const RedirectScreen = lazy(() => import('../RedirectScreen'));
const OAuthSignIn = lazy(() => import('../OAuthSignIn'));
const OAuthAuthorize = lazy(() => import('../OAuthAuthorize'));

export default function App() {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [loadPage, setLoadPage] = useState(false);
  const [loginRedirectUri, setLoginRedirectUri] = useState('');
  const [redirectToAuthorize, setRedirectToAuthorize] = useState(false);
  const [redirectToOAuthSignIn, setRedirectToOAuthSignIn] = useState(false);
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const whiteLabelData = useWhiteLabelData();

  const onHelpClick = () => {
    setHelpPanelOpen(!helpPanelOpen);
  };

  useEffect(() => {
    if (inIframe()) return;

    if (window.location.href.includes('logout')) removeCookies();

    const isOAuth = window.location.pathname.includes('oauth');
    const isLoggedIn = hasAuthToken();

    if (isLoggedIn && !isOAuth) {
      if (
        !(
          window.location.href.includes(paths.mockApprove) ||
          window.location.href.includes(paths.mockDeny)
        )
      ) {
        performRedirect();
      } else {
        setLoadPage(true);
      }
    } else if (isOAuth) {
      if (isLoggedIn) setRedirectToAuthorize(true);
      if (!isLoggedIn) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('skipSignup')) {
          setLoginRedirectUri(window.location.href);
        } else {
          setRedirectToOAuthSignIn(true);
        }
      }
      setLoadPage(true);
    } else setLoadPage(true);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('email')) {
      setEmailOrPhone(urlParams.get('email'));
    }

    initThirdPartyScripts();
    handleTitleAndFavicon(whiteLabelData);
  }, [whiteLabelData]);

  if (!loadPage) return null;

  return (
    <HeroElementContainer>
      <Suspense fallback={null}>
        <WithAdWrapper>
          <AppWrapper style={{ flex: 1 }}>
            {redirectUrl && <Redirect to={paths.ssoRedirect} />}
            {loginRedirectUri && (
              <Redirect
                to={{
                  pathname: paths.root,
                  search: `redirect_uri=${encodeURIComponent(
                    loginRedirectUri,
                  )}`,
                }}
              />
            )}
            {redirectToOAuthSignIn && (
              <Redirect
                to={{
                  pathname: paths.oauthSignIn,
                  search: window.location.search,
                }}
              />
            )}
            {redirectToAuthorize && (
              <Redirect
                to={{
                  pathname: paths.oauthAuthorize,
                  search: window.location.search,
                }}
              />
            )}
            <Switch>
              <Route
                path={paths.root}
                exact
                render={(props) => (
                  <PasswordLogin
                    {...props}
                    emailOrPhone={emailOrPhone}
                    setEmailOrPhone={setEmailOrPhone}
                    setRedirectUrl={setRedirectUrl}
                    whiteLabelData={whiteLabelData}
                  />
                )}
              />
              <Route
                path={paths.twoFactor}
                exact
                render={(props) => (
                  <ProtectedRoute>
                    <VerificationCodeLogin
                      {...props}
                      whiteLabelData={whiteLabelData}
                    />
                  </ProtectedRoute>
                )}
              />
              <Route
                path={paths.requestCode}
                exact
                render={(props) => (
                  <RequestCode
                    {...props}
                    emailOrPhone={emailOrPhone}
                    setEmailOrPhone={setEmailOrPhone}
                    setRedirectUrl={setRedirectUrl}
                    onHelpClick={onHelpClick}
                  />
                )}
              />
              <Route
                path={paths.mockApprove}
                exact
                render={(props) => <MockUserApproval {...props} approve />}
              />
              <Route
                path={paths.mockDeny}
                exact
                render={(props) => (
                  <MockUserApproval {...props} approve={false} />
                )}
              />
              <Route
                path={paths.submitCode}
                exact
                render={(props) => (
                  <PasswordlessLogin
                    {...props}
                    emailOrPhone={emailOrPhone}
                    onHelpClick={onHelpClick}
                  />
                )}
              />
              <Route
                path={paths.ssoRedirect}
                exact
                render={(props) => (
                  <RedirectScreen {...props} redirectUrl={redirectUrl} />
                )}
              />
              <Route
                path={paths.oauthSignIn}
                exact
                render={(props) => <OAuthSignIn {...props} />}
              />
              <Route
                path={paths.oauthAuthorize}
                exact
                render={(props) => <OAuthAuthorize {...props} />}
              />
              <Route
                render={(props) => <Redirect {...props} to={paths.root} />}
              />
            </Switch>
            <SidePanelWrapper show={helpPanelOpen}>
              <SidePanel onClose={onHelpClick} />
            </SidePanelWrapper>
          </AppWrapper>
          {!whiteLabelData.hideMarketing && <LoginPageAds />}
        </WithAdWrapper>
      </Suspense>
    </HeroElementContainer>
  );
}
