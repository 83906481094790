import React, { useCallback, useEffect, useState } from 'react';
import { useTracking } from '@podiumhq/tracking';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import useScript from '../../lib/hooks/useScript';
import { APPLE_CLIENT_ID, APPLE_AUTH_URI, MITHRANDIR_URL } from '../../lib/env';
import cookies from '../../lib/cookies';

export default function SignInWithApple({ onLogin, containerDimensions }) {
  const { trackEvent } = useTracking();
  const [refresh, setRefresh] = useState(0);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [aToken, setAToken] = useState('');
  const status = useScript({ src: APPLE_AUTH_URI });

  const handleLogin = useCallback(
    (event) => {
      const {
        authorization: { id_token: idToken },
      } = event.detail;
      trackEvent('authorization.login', { type: 'apple received jwt' });
      const decodedJwt = jwtDecode(idToken);
      onLogin({ source: 'apple', jwt: idToken }, decodedJwt.email);
    },
    [onLogin, trackEvent],
  );

  useEffect(() => {
    document.addEventListener('AppleIDSignInOnSuccess', handleLogin);
    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleLogin);
    };
  }, [handleLogin]);

  useEffect(() => {
    if (scriptLoaded) return;
    if (status.loaded && !status.error) {
      setScriptLoaded(true);
    }
  }, [status, scriptLoaded]);

  useEffect(() => {
    const jwt = cookies.get('aToken');
    if (jwt) {
      setAToken(jwt);
    }
  }, []);

  useEffect(() => {
    if (!enabled) return;
    if (aToken) {
      const decodedJwt = jwtDecode(aToken);
      const unixNow = Math.floor(Date.now() / 1000);
      if (decodedJwt.exp > unixNow) {
        onLogin({ source: 'apple', jwt: aToken }, decodedJwt.email);
      }
    }
  }, [onLogin, aToken, enabled]);

  useEffect(() => {
    if (!!APPLE_AUTH_URI && !!APPLE_CLIENT_ID) {
      setEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (!enabled || !scriptLoaded) return;

    if (window?.AppleID?.auth) {
      try {
        window.AppleID.auth.init({
          clientId: APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI: MITHRANDIR_URL,
          usePopup: true,
        });
      } catch {
        trackEvent('authorization.login', { type: 'apple init error' });
      }
    } else {
      setTimeout(() => {
        setRefresh(refresh + 1);
      }, 250);
    }
  }, [scriptLoaded, refresh, enabled, trackEvent, containerDimensions?.width]);

  const calculateTextPosition = (textContainerWidth = 0, textWidth = 0) => {
    // to physically align with google button
    // in pixels
    const buffer = 10;

    return textContainerWidth / 2 - textWidth / 2 + buffer;
  };

  const setLabelPosition = () => {
    // Based off CSS computed width; #appleid-signin > div > div > svg:nth-child(3) > text
    // in pixels
    const computedTextWidth = 125;

    return containerDimensions?.width
      ? calculateTextPosition(containerDimensions.width, computedTextWidth)
      : null;
  };

  return (
    enabled && (
      <AppleSignIn
        id="appleid-signin"
        data-color="white"
        data-height="34"
        data-type="sign in"
        data-mode="left-align"
        data-logo-size="large"
        data-logo-position="12"
        data-width={containerDimensions?.width || null}
        data-label-position={setLabelPosition()}
        data-border="false"
        style={{
          height: '40px',
          marginTop: '16px',
          border: '#dadce0',
          borderStyle: 'solid',
          borderWidth: 'thin',
          borderRadius: '3px',
          cursor: 'pointer',
          paddingTop: '4px',
          color: '#3C4043',
        }}
      />
    )
  );
}

const AppleSignIn = styled.div`
  svg {
    fill: #3c4043 !important;
    textlength: 115px !important;
    text {
      font-size: 13px !important;
      font-family: 'Graphik', 'Helvetica', 'sans-serif';
      font-weight: 500;
      letter-spacing: 2.25px;
    }
  }
  span {
    height: unset !important;
  }
`;
