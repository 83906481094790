import { FREE_TRIALS_PAGE } from '../env';

const paths = {
  oauthAuthorize: '/oauth/authorize',
  oauthSignIn: '/oauth/sign-in',
  requestCode: '/request-code',
  root: '/',
  ssoRedirect: '/sso-redirect',
  submitCode: '/submit-code',
  twoFactor: '/two-factor',
  signUp: FREE_TRIALS_PAGE,
  mockApprove: '/approve',
  mockDeny: '/deny',
};

export default paths;
