import { RECAPTCHA_SITE_KEY, BYPASS_CAPTCHA } from '../env';

function fetchTokenFromGoogle(grecaptchaClient = {}) {
  return new Promise((resolve) => {
    if (grecaptchaClient?.ready) {
      grecaptchaClient.ready(() => {
        grecaptchaClient
          .execute(RECAPTCHA_SITE_KEY)
          .then((token) => resolve(token))
          .catch(() => resolve(''));
      });
    } else {
      resolve('');
    }
  });
}

/**
 * This will get a recaptcha token if recaptcha thinks it's valid
 *
 * @returns {Promise} that has the token
 */
export const getRecaptchaToken = async (options = {}) => {
  const grecaptchaClient = options.grecaptchaClient || window.grecaptcha;
  const canBypassCaptcha = options.bypassCaptcha || BYPASS_CAPTCHA;
  const search = options.search || window.location.search;
  const queryParams = new URLSearchParams(search);

  let token;
  if (canBypassCaptcha) {
    // User's can bypass when they're in Garden
    token = Promise.resolve(undefined);
  } else if (queryParams.has('bypassToken')) {
    // Link and QA have bypass tokens, which do not run the recaptcha flow
    token = Promise.resolve(queryParams.get('bypassToken'));
  } else {
    // All other traffic must fetch tokens from Google, otherwise Stormcrow will
    // reject login attempts.
    token = fetchTokenFromGoogle(grecaptchaClient);
  }

  return token;
};
