import { useMemo } from 'react';

export const whiteLabelConfigs = [
  {
    subdomain: 'forddirect',
    pageTitle: 'Login | FordDirect',
    logoUrl: '/white-label/forddirect/logo.png',
    faviconUrl: '/white-label/forddirect/favicon.ico',
    hideMarketing: true,
    disclaimerText:
      'By clicking "Sign In", you are representing and warranting that you will not use the system or any services except in compliance with all applicable laws and regulations, including but not limited to the Telephone Consumer Protection Act, and that you will not send any message without first obtaining the requisite consent from the recipient and ensuring the recipient has not opted out from receiving messages.',
  },
];

export const useWhiteLabelData = () => {
  const subdomain = window.location.host.split('.')[0];
  // TODO: Remove temp whitelabel testing param before going to prod
  const urlsSearchParams = new URLSearchParams(window.location.search);
  const whiteLabelParam = urlsSearchParams.get('whiteLabel');
  const whiteLabelToSearchFor = whiteLabelParam || subdomain;
  const validWhiteLabel = whiteLabelConfigs.find(
    (config) => config.subdomain === whiteLabelToSearchFor,
  );

  return useMemo(() => validWhiteLabel || {}, [validWhiteLabel]);
};

// TODO: Remove hard coded forddirect references
export const handleTitleAndFavicon = (whiteLabel) => {
  const defaultTitle = 'Login | Podium - Messaging Tools for Local Business';
  const defaultFaviconUrl = '/favicon.ico';
  document.title = whiteLabel.pageTitle || defaultTitle;

  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = whiteLabel.faviconUrl || defaultFaviconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
};
