import gql from 'graphql-tag';

export const PASSWORD_LOGIN_V2_SUCCESS_TYPE = 'LoginSuccess';
export const PASSWORD_LOGIN_V2_MFA_TYPE = 'MfaChallenge';
export const PASSWORD_LOGIN_V2_UNEXPECTED_TYPE = 'UnexpectedRequest';
export const PASSWORDLESS_LOGIN_V2_INCORRECT_CODE_TYPE = 'IncorrectCode';

export const MOCK_USER_APPROVAL_APPROVE_DENY_SUCCESSFULLY_TYPE =
  'ApproveDenySuccessfully';
export const MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_INVALID_TYPE =
  'ApproveDenyRequestInvalid';
export const MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_EXPIRED =
  'ApproveDenyRequestExpired';
export const MOCK_USER_APPROVAL_APPROVE_DENY_INVALID_STATE_TRANSITION =
  'ApproveDenyInvalidStateTransition';

export const PASSWORD_LOGIN_V2 = gql`
  mutation passwordLoginV2Mutation($input: PasswordLoginInput!) {
    passwordLoginV2(input: $input) {
      ... on ${PASSWORD_LOGIN_V2_SUCCESS_TYPE} {
        idToken
        refreshToken
      }
      ... on ${PASSWORD_LOGIN_V2_UNEXPECTED_TYPE} {
        key
        message
      }
      ... on ${PASSWORD_LOGIN_V2_MFA_TYPE} {
        mfaToken
        channel
        contact
      }
    }
  }
`;

export const GOOGLE_JWT_LOGIN = gql`
  mutation googleJwtLoginMutation($input: GoogleJwtLoginInput!) {
    googleJwtLogin(input: $input) {
      idToken
    }
  }
`;

export const APPLE_JWT_LOGIN = gql`
  mutation appleJwtLoginMutation($input: AppleJwtLoginInput!) {
    appleJwtLogin(input: $input) {
      idToken
    }
  }
`;

export const GENERATE_CODE = gql`
  mutation generateCodeMutation($emailOrPhone: String!) {
    generateLoginCode(emailOrPhone: $emailOrPhone) {
      processed
    }
  }
`;

export const PASSWORDLESS_LOGIN = gql`
  mutation passwordlessLoginMutation($input: PasswordlessLoginInput!) {
    passwordlessLogin(input: $input) {
      idToken
      refreshToken
    }
  }
`;

export const PASSWORDLESS_LOGIN_V2 = gql`
  mutation passwordlessLoginV2Mutation($input: PasswordlessLoginV2Input!) {
    passwordlessLoginV2(input: $input) {
      ... on ${PASSWORD_LOGIN_V2_SUCCESS_TYPE} {
        idToken
        refreshToken
      }
      ... on ${PASSWORDLESS_LOGIN_V2_INCORRECT_CODE_TYPE} {
        attemptsExceeded
      }
      ... on ${PASSWORD_LOGIN_V2_UNEXPECTED_TYPE} {
        key
        message
      }
    }
  }
`;

export const OAUTH_AUTHORIZATION = gql`
  mutation oauthAuthorizeMutation(
    $idToken: String!
    $input: OauthAuthorizeInput!
  ) {
    oauthAuthorize(idToken: $idToken, input: $input) {
      redirectUri
    }
  }
`;

export const OAUTH_DENY = gql`
  mutation oauthDenyMutation($idToken: String!, $input: OauthAuthorizeInput!) {
    oauthDeny(idToken: $idToken, input: $input) {
      redirectUri
    }
  }
`;

export const USER_MOCK_APPROVE = gql`
  mutation approveMockRequest($mockRequestToken: String!) {
    approveMockRequest(mockRequestToken: $mockRequestToken) {
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_SUCCESSFULLY_TYPE} { __typename, message }
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_INVALID_TYPE} { __typename, message }
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_EXPIRED} { __typename, message }
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_INVALID_STATE_TRANSITION} { __typename, message }
    }
  }
`;

export const USER_MOCK_DENY = gql`
  mutation denyMockRequet($mockRequestToken: String!) {
    denyMockRequest(mockRequestToken: $mockRequestToken) {
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_SUCCESSFULLY_TYPE} { __typename, message }
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_INVALID_TYPE} { __typename, message }
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_REQUEST_EXPIRED} { __typename, message }
      ... on ${MOCK_USER_APPROVAL_APPROVE_DENY_INVALID_STATE_TRANSITION} { __typename, message }
    }
  }
`;
