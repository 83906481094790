import isQABot from './isQABot';
import { setupTracking, setupExperiment } from './amplitude';

export function initThirdPartyScripts() {
  setTimeout(() => {
    try {
      if (process.env.NODE_ENV === 'production' && !isQABot()) {
        setupTracking();
      }
      setupExperiment();
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Error loading third party scripts: ${e}`);
    }
  }, 2000);
}
