import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

const mobileScreenWidth = '768px';
const show = `
  opacity: 1.0;
  min-height: 40px;
  margin-bottom: 32px;
  visibility: visible;

  @media (max-width: ${mobileScreenWidth}) {
    min-height: 64px;
  }
`;
const hide = `opacity: 0; height: 0px; visibility: hidden;`;

export const Banner = styled.div`
  align-items: center;
  background-color: rgb(246, 197, 196, 0.4);
  border-radius: 4px;
  color: ${colors.poppyRed};
  display: flex;
  font-size: 14px;
  line-height: 23px;
  transition: all 200ms 200ms;
  transition-timing-function: ease-in-out;
  width: 100%;

  ${(props) => (props.show ? show : hide)};
`;

export const BannerIcon = styled.div`
  margin-left: 20px;
  margin-right: 12px;
  display: flex;
`;
