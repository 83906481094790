import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormInput, Button, colors } from '@podiumhq/podium-ui';
import { HeroElement } from '@podiumhq/first-meaningful-paint';
import { useMutation } from '@apollo/client';
import { useTracking } from '@podiumhq/tracking';

import {
  ButtonHolder,
  FormInputContainer,
  Divider,
  HelpText,
  Input,
  LoginContainer,
  Logo,
  PolicyText,
} from './styles';
import logo from '../../images/Logo.png';
import paths from '../../lib/paths';
import {
  PASSWORDLESS_LOGIN_V2,
  PASSWORDLESS_LOGIN_V2_INCORRECT_CODE_TYPE,
  PASSWORD_LOGIN_V2_SUCCESS_TYPE,
  PASSWORD_LOGIN_V2_UNEXPECTED_TYPE,
} from '../../graphql/mutations';
import { setCookies, getCookie, removeCookies } from '../../lib/cookies';
import { InvalidLoginBanner } from '../index';
import { Header, Text } from '../PasswordlessLogin/styles';
import useTrackInteraction from '../../lib/hooks/useTrackInteraction';
// TODO: Remove hard coded forddirect references

const attemptsExceededErrorMessage =
  'You have exceeded the maximum number of attempts. Please sign in again.';
const incorrectCodeErrorMessage =
  'Incorrect or expired code. Please try again.';
const missingVerificationCodeErrorMessage = 'Verification code is required';
const unexpectedErrorMessage = 'Something went wrong. Please try again.';
const unknownErrorMessage = 'Something went wrong.';
const linkStyle = { textDecoration: 'none', color: colors.cobaltBlue };
const helpLink =
  'https://www.podium.com/knowledgebase/s/article/Enable-MFA-Preferences';

export default function VerificationCodeLogin({ whiteLabelData }) {
  const { trackEvent } = useTracking();
  const [userAttemptsExceeded, setUserAttemptsExceeded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isMissingVerificationCode, setIsMissingVerificationCode] =
    useState(false);

  const { interactionSucceeded } = useTrackInteraction('login_with_password');

  const [mfaLogin, { loading }] = useMutation(PASSWORDLESS_LOGIN_V2, {
    onCompleted(data) {
      switch (data.passwordlessLoginV2.__typename) {
        case PASSWORD_LOGIN_V2_SUCCESS_TYPE: {
          const {
            passwordlessLoginV2: { idToken, refreshToken },
          } = data;

          setCookies({ idToken, refreshToken, hasRecentLogin: true });

          interactionSucceeded();

          break;
        }
        case PASSWORDLESS_LOGIN_V2_INCORRECT_CODE_TYPE: {
          const {
            passwordlessLoginV2: { attemptsExceeded },
          } = data;

          if (attemptsExceeded) {
            setUserAttemptsExceeded(attemptsExceeded);
            setErrorMessage(attemptsExceededErrorMessage);
            removeCookies();
          } else {
            setErrorMessage(incorrectCodeErrorMessage);
          }
          break;
        }
        case PASSWORD_LOGIN_V2_UNEXPECTED_TYPE: {
          setErrorMessage(unexpectedErrorMessage);
          break;
        }
        default: {
          throw new Error(unknownErrorMessage);
        }
      }
    },
  });

  const onSubmit = () => {
    setErrorMessage('');
    setIsMissingVerificationCode(!verificationCode);

    const mfaToken = getCookie('mfaToken');

    if (mfaToken) {
      mfaLogin({
        variables: {
          input: { userChannelToken: mfaToken, code: verificationCode },
        },
      });
    }
  };

  const onVerificationCodeChange = (input) => {
    if (isMissingVerificationCode && input) setIsMissingVerificationCode(false);

    setVerificationCode(input.trim());
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const channel = getCookie('channel');
  const contact = getCookie('contact');
  function trackHelpGuide() {
    trackEvent('login.helpGuide.clicked', { type: 'mfa' });
  }

  return (
    <HeroElement heroId="verification-code-login">
      <LoginContainer>
        <Logo>
          <img
            src={whiteLabelData.logoUrl || logo}
            width="50%"
            alt={whiteLabelData.logoUrl ? 'FordDirect Logo' : 'Podium Logo'}
          />
        </Logo>
        <Header>
          Headed your way...{' '}
          <span role="img" aria-label="Grinning Face With Smiling Eyes">
            😄
          </span>
        </Header>
        <Text>
          Please check for an MFA verification code via {channel} to {contact}
        </Text>
        <InvalidLoginBanner show={errorMessage} message={errorMessage} />
        <Input>
          <FormInputContainer>
            <FormInput
              autoFocus
              disabled={loading || userAttemptsExceeded}
              errorMessage={
                isMissingVerificationCode && missingVerificationCodeErrorMessage
              }
              id="verificationCode"
              name="verificationCode"
              onChange={(e) => onVerificationCodeChange(e.target.value)}
              onFocus={() => setIsMissingVerificationCode(false)}
              onKeyDown={onEnter}
              placeholder="Enter code"
              tabindex="0"
              type="text"
              value={verificationCode}
              autoComplete="off"
            />
          </FormInputContainer>
        </Input>
        <ButtonHolder>
          <Button
            disabled={loading || userAttemptsExceeded}
            fullwidth
            id="verifyButton"
            isLoading={loading}
            loadingText="Signing in..."
            onClick={onSubmit}
            size="large"
            variant="primary"
            width="420px"
          >
            Sign in
          </Button>
        </ButtonHolder>
        {whiteLabelData.disclaimerText && (
          <PolicyText>{whiteLabelData.disclaimerText}</PolicyText>
        )}
        <HelpText>
          <Link id="backToLoginPage" to={paths.root} style={linkStyle}>
            Return to Login
          </Link>
          <Divider />
          <a
            href={helpLink}
            onClick={() => {
              trackHelpGuide();
            }}
            style={linkStyle}
          >
            Need more help?
          </a>
        </HelpText>
      </LoginContainer>
    </HeroElement>
  );
}
