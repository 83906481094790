import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

export const Logo = styled.div`
  margin: 0 auto 72px;
  text-align: center;
  transition: all 200ms 200ms;
  white-space: nowrap;
`;

export const Input = styled.div`
  padding-bottom: 32px;
  position: relative;
`;

export const ButtonHolder = styled.div`
  width: 100%;
`;

export const Divider = styled.div`
  margin-top: 24px;
  background: #d9d9da;
  width: 100%;
  height: 1px;
`;
export const HelpText = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
  padding: 0 40px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

export const PolicyText = styled.div`
  font-size: 10px;
  font-weight: normal;
  margin-top: 30px;
  padding: 0 10px;
  text-align: center;
  color: ${colors.steel};
`;

export const ToggleShow = styled.div`
  position: absolute;
  top: 39px;
  right: 16px;
`;

export const ShowButton = styled.div`
  color: ${colors.cobaltBlue};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
`;
