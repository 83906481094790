import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../lib/paths';
import { hasTwoFactorJwt } from '../../lib/cookies';

function ProtectedRoute({ children }) {
  return hasTwoFactorJwt() ? (
    children
  ) : (
    <Redirect to={{ pathname: paths.root }} />
  );
}

export default ProtectedRoute;
