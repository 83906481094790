import { tracking, experiment } from '@podiumhq/tracking';
import TRACKING_EVENTS from './trackingEvents';
import { AMPLITUDE_TOKEN, AMPLITUDE_EXPERIMENT_TOKEN } from '../env';

export function setupTracking() {
  tracking.init({
    enabled: true,
    events: TRACKING_EVENTS,
    debug: true,
    amplitudeToken: AMPLITUDE_TOKEN,
  });
}

export function setupExperiment() {
  if (AMPLITUDE_EXPERIMENT_TOKEN) {
    experiment.init({
      connectToAnalytics: true, // to connect experiments to amplitude analytics
      debug: process.env.NODE_ENV === 'development',
      enabled: true,
      environmentKey: AMPLITUDE_EXPERIMENT_TOKEN, // referred to as the "deployment key"
    });
  }
}
