import gql from 'graphql-tag';

export const OAUTH_PREAUTHORIZATION = gql`
  query OAuthPreauthorize($idToken: String!, $input: OauthAuthorizeInput!) {
    oauthPreauthorize(idToken: $idToken, input: $input) {
      ... on OauthPreauthorizeData {
        client {
          name
          logo_uri
        }
        scopes {
          name
          slug
        }
      }
      ... on OauthRedirect {
        redirectUri
      }
    }
  }
`;

export const USER_NEEDS_REDIRECT_QUERY = gql`
  query UserNeedsRedirect($emailOrPhone: String!) {
    userNeedsRedirect(emailOrPhone: $emailOrPhone) {
      needRedirect
      redirectUrl
      ssoEnabled
    }
  }
`;
