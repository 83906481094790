import React from 'react';
import { StatusIcon } from '@podiumhq/podium-ui';
import { ScreenWrapper, IconHolder, Header, Text } from './styles';
import { ContentContainer } from '../App/styles';

const tenSeconds = 3000;

export default function RedirectScreen({ redirectUrl }) {
  setTimeout(() => {
    window.location.replace(redirectUrl);
  }, tenSeconds);

  return (
    <ContentContainer>
      <ScreenWrapper>
        <IconHolder>
          <StatusIcon />
        </IconHolder>
        <Header>Hang tight for a moment.</Header>
        <Text>
          We're sending you to your organization's SSO provider. Please use
          their sign in to continue to Podium.
        </Text>
      </ScreenWrapper>
    </ContentContainer>
  );
}
