import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

export const ExpandSection = styled.div`
  border-bottom: 1px solid ${colors.mystic};
  margin: 0 16px;
  padding: 24px;
`;

export const ExpandLabel = styled.div`
  align-items: flex-start;
  color: ${colors.mineShaft};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
`;

export const LabelText = styled.div`
  padding-right: 16px;
`;

const rotateUp = 'transform: rotate(270deg);';

export const ToggleExpand = styled.div`
  height: 25px;
  transform: rotate(90deg);
  width: 25px;
  ${(props) => (props.expanded ? rotateUp : '')};
`;

const show = `visibility: visible;`;
const hide = `visibility: hidden;`;

export const ExpandedContentHolder = styled.div`
  ${(props) => (props.expanded ? show : hide)};
`;

export const ExpandedContent = styled.div`
  font-size: 16px;
  line-height: 23px;
  margin-top: 16px;
  padding-right: 56px;
`;
