import { STORMCROW_HOST } from '../env';

export function performRedirect() {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams.has('redirect_uri')
    ? `${STORMCROW_HOST}/?redirect_uri=${encodeURIComponent(
        urlParams.get('redirect_uri'),
      )}`
    : STORMCROW_HOST;

  window.location.replace(redirectUrl);
}
