import React from 'react';
import { IconChevron, colors } from '@podiumhq/podium-ui';
import {
  ExpandLabel,
  ExpandSection,
  ExpandedContentHolder,
  ExpandedContent,
  LabelText,
  ToggleExpand,
} from './styles';

export default function ExpandableDrawer({
  children,
  expanded,
  label,
  onClick,
}) {
  return (
    <ExpandSection>
      <ExpandLabel onClick={onClick}>
        <LabelText>{label}</LabelText>
        <ToggleExpand expanded={expanded}>
          <IconChevron fill={colors.steel} size="large" />
        </ToggleExpand>
      </ExpandLabel>
      <ExpandedContentHolder expanded={expanded}>
        {expanded && <ExpandedContent>{children}</ExpandedContent>}
      </ExpandedContentHolder>
    </ExpandSection>
  );
}
