import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

export const ScreenWrapper = styled.div`
  padding-top: 112px;
`;

export const IconHolder = styled.div`
  height: 48px;
  width: 48px;
  margin: auto;
`;

export const Header = styled.div`
  color: ${colors.mineShaft};
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 18px;
  padding-top: 40px;
  text-align: center;
`;

export const Text = styled.div`
  color: ${colors.mineShaft};
  font-size: 16px;
  font-weight: normal;
  line-height: 23px;
  margin: auto;
  padding-left: 12px;
  padding-bottom: 12px;
  width: 392px;
  text-align: center;
`;

export const DoneText = styled.div`
  color: ${colors.mineShaft};
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  margin: auto;
  padding-left: 12px;
  padding-bottom: 12px;
  width: 392px;
  text-align: center;
`;
