import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
/*
Google and Apple Signins Button require hard values. So we're going to get the width of the
current container and let the container determine the size as if it was a relative value.
*/
const useElementDimensions = () => {
  const [elementDimensions, setDimensions] = useState(null);
  const [elementDimensionsRef, setElementDimensionsRef] = useState(null);

  useEffect(() => {
    // Throttle resize for performance reasons
    const handleResize = throttle(() => {
      if (elementDimensionsRef) {
        const rect = elementDimensionsRef?.getBoundingClientRect();
        setDimensions(rect);
      }
    }, 100);

    if (elementDimensionsRef) {
      // Fire once initially once ref is set
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [elementDimensionsRef]);

  return { elementDimensions, elementDimensionsRef, setElementDimensionsRef };
};

export default useElementDimensions;
