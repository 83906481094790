import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

export const Header = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 16px;
`;

export const Text = styled.div`
  color: ${colors.mineShaft};
  font-size: 16px;
  padding-bottom: 32px;
  line-height: 24px;
`;

export const Input = styled.div`
  padding-bottom: 40px;
`;

export const ButtonHolder = styled.div`
  padding-bottom: 8px;
`;

export const HelpText = styled.div`
  color: ${colors.cobaltBlue};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const HelpTextClickable = styled.div`
  color: ${colors.cobaltBlue};
  cursor: pointer;
  padding: 24px;
`;
