import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  IconButton,
  SlideFade,
  useMediaQuery,
  useDisclosure,
} from '@podiumhq/design-system';

import MobileApp from '../../images/MobileApp.png';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'Unknown';
}

export default function PasswordLogin() {
  const mobileOS = getMobileOperatingSystem();
  const [isSmallScreen] = useMediaQuery('(max-width: 374px)');
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (!(mobileOS === 'iOS' || mobileOS === 'Android')) {
    return null;
  }

  const appStoreURL =
    mobileOS === 'Android'
      ? 'https://play.google.com/store/apps/details?id=com.ionicframework.ionicapp410897&pli=1'
      : 'https://apps.apple.com/us/app/podium-interaction-platform/id971412288';

  return (
    <SlideFade in={isOpen && isImageLoaded}>
      <Box
        background="radial-gradient(63.15% 164.71% at 79.57% 107.06%, #555C67 0%, #000000 100%)"
        boxShadow="0px 0px 0px 1px rgba(18, 21, 26, 0.08), 0px 8px 16px rgba(18, 21, 26, 0.02), 0px 16px 32px -4px rgba(18, 21, 26, 0.04), 0px 32px 64px -8px rgba(18, 21, 26, 0.04)"
        borderRadius="16px 16px 0px 0px"
        padding="18px 24px"
        height="85px"
        position="relative"
      >
        <Flex>
          <Box minWidth="184px">
            <Text
              color="silver.lightest"
              fontWeight="medium"
              marginBottom="6px"
            >
              Podium is better on the app
            </Text>
            <Text
              color="silver.lightest"
              textDecoration="underline"
              as="a"
              href={appStoreURL}
            >
              Download the mobile app
            </Text>
          </Box>
          <Box maxWidth="100%" padding={isSmallScreen ? '0 6px' : '0 30px'}>
            <Image
              src={MobileApp}
              maxWidth="100%"
              onLoad={() => setIsImageLoaded(true)}
            />
          </Box>
        </Flex>
        <IconButton
          onClick={onClose}
          aria-label="Dismiss banner"
          size="xs"
          type="XClose"
          variant="ghost"
          fillColor="grey.lighter"
          position="absolute"
          top="12px"
          right="12px"
        />
      </Box>
    </SlideFade>
  );
}
