const TRACKING_EVENTS = {
  passwordlessLogin: {
    error: true,
    success: true,
  },
  passwordLogin: {
    checkUserRedirect: {
      error: true,
    },
    error: true,
    success: true,
  },
  authorization: {
    login: true,
  },
  login: {
    helpGuide: {
      clicked: true,
    },
  },
};
export default TRACKING_EVENTS;
