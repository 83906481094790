import { useEffect, useState } from 'react';

const cachedScripts = [];

const useScript = ({ code, src } = {}) => {
  const [state, setState] = useState({
    error: false,
    loaded: false,
  });

  useEffect(
    () => {
      const scriptValue = code || src;

      // If cachedScripts array already includes scriptValue that means another
      // instance of this hook already loaded this script, so no need to load
      // again.
      if (cachedScripts.includes(scriptValue)) {
        setState({
          loaded: true,
          error: false,
        });
      } else if (scriptValue) {
        cachedScripts.push(scriptValue);

        // Create script
        const script = document.createElement('script');

        if (src) {
          script.src = src;
        }

        if (code) {
          script.type = 'text/javascript';
          script.text = code;
        }

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          if (src) {
            const index = cachedScripts.indexOf(src);
            if (index >= 0) cachedScripts.splice(index, 1);
          }
          script.remove();

          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', onScriptError);
        };
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, src], // Only re-run effect if script src or inlineContent changes
  );

  return state;
};

export default useScript;
