/**
 * Copied and modified from Kazaam's `useTrackInteraction` hook. Will be
 * extracted into a library in the future.
 */

import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';

/**
 * @typedef {object} TrackInteractionResults
 * @property {function} interactionAttempted Function to call when an interaction is first attempted.
 * @property {function} interactionSucceeded Function to call when an interaction has succeeded.
 */

/**
 * Track an attempt and success of a defined product interaction.
 * @param {string} interactionName The name of the product interaction
 * @returns {TrackInteractionResults} callbacks to trigger the telemetry
 */
export default function useTrackInteraction(interactionName) {
  const interactionAttempted = useCallback(() => {
    console.debug(`🎬 Interaction attempted: ${interactionName}`);
    emitDatadogEvent(interactionName, 'attempted');
  }, [interactionName]);

  const interactionSucceeded = useCallback(() => {
    console.debug(`🎬 Interaction succeeded: ${interactionName}`);
    emitDatadogEvent(interactionName, 'succeeded');
  }, [interactionName]);

  return {
    interactionAttempted,
    interactionSucceeded,
  };
}

/**
 * @param {string} interactionName
 * @param {string} status 'attempted' | 'succeeded'
 */
function emitDatadogEvent(interactionName, status) {
  const actionName = `interaction_${interactionName}`;
  datadogRum.addAction(actionName, {
    interaction_status: status,
  });
}
