import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormInput, colors, Button } from '@podiumhq/podium-ui';
import { useMutation, useApolloClient } from '@apollo/client';
import { HeroElement } from '@podiumhq/first-meaningful-paint';
import { useTracking } from '@podiumhq/tracking';
import { Box } from '@podiumhq/design-system';
import styled from 'styled-components';
import {
  ButtonHolder,
  PolicyText,
  Input,
  Logo,
  ToggleShow,
  ShowButton,
  Divider,
} from './styles';
import { ContentContainer } from '../App/styles';
import paths from '../../lib/paths';
import { removeCookies, setCookies } from '../../lib/cookies';
import { isValidEmailOrPhone, formatIfPhone } from '../../lib/validation';
import useScript from '../../lib/hooks/useScript';
import { getRecaptchaToken } from '../../lib/recaptcha/getRecaptchaToken';
import useTrackInteraction from '../../lib/hooks/useTrackInteraction';
import {
  APPLE_JWT_LOGIN,
  GOOGLE_JWT_LOGIN,
  PASSWORD_LOGIN_V2,
  PASSWORD_LOGIN_V2_SUCCESS_TYPE,
  PASSWORD_LOGIN_V2_MFA_TYPE,
} from '../../graphql/mutations';
import { USER_NEEDS_REDIRECT_QUERY } from '../../graphql/queries';
import { InvalidLoginBanner } from '..';
import logo from '../../images/Logo.png';
import {
  RECAPTCHA_SITE_KEY,
  GOOGLE_CLIENT_ID,
  GOOGLE_ACCOUNT_URI,
  APPLE_CLIENT_ID,
  APPLE_AUTH_URI,
} from '../../lib/env';
import SignInWithGoogle from '../SignInWithGoogle';
import SignInWithApple from '../SignInWithApple';
import useElementDimensions from '../../lib/hooks/useElementDimensions';
import MobileAppBanner from './MobileAppBanner';

// TODO: Remove hard coded forddirect references

const LINK_STYLE = { textDecoration: 'none', color: colors.cobaltBlue };

const invalidUsernameText = 'Invalid email address';
const missingUsernameText = 'Email or mobile number is required';
const missingPasswordText = 'Password is required';
const invalidLoginText = (usernameType) =>
  `Incorrect ${usernameType} or password. Please try again.`;
const internalServerErrorMessage = 'Something went wrong. Please try again.';

export default function PasswordLogin({
  emailOrPhone,
  setEmailOrPhone,
  setRedirectUrl,
  whiteLabelData,
}) {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInputVisible, setPasswordInputVisible] =
    useState(!!emailOrPhone);
  const [missingUsername, setMissingUsername] = useState(false);
  const [missingPassword, setMissingPassword] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [thirdPartyJwt, setThirdPartyJwt] = useState();
  const { trackEvent } = useTracking();
  const { elementDimensions, setElementDimensionsRef } = useElementDimensions();

  const { interactionAttempted, interactionSucceeded } = useTrackInteraction(
    'login_with_password',
  );

  const shouldEnforceCaptcha = RECAPTCHA_SITE_KEY !== '';

  const recaptchaSrc = shouldEnforceCaptcha
    ? `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    : null;

  useScript({ async: true, src: recaptchaSrc });

  const client = useApolloClient();
  const [passwordLogin, { loading }] = useMutation(PASSWORD_LOGIN_V2, {
    onError() {
      const userIdentifier = emailOrPhone.includes('@')
        ? 'email'
        : 'mobile number';

      trackEvent(`passwordLogin.error`, { userIdentifier: emailOrPhone });
      setErrorMessage(invalidLoginText(userIdentifier));
      setInvalidLogin(true);
      setLoginLoading(false);
    },
    onCompleted(data) {
      switch (data.passwordLoginV2.__typename) {
        case PASSWORD_LOGIN_V2_SUCCESS_TYPE: {
          const {
            passwordLoginV2: { idToken, refreshToken },
          } = data;

          interactionSucceeded();

          trackEvent(`passwordLogin.success`, { userIdentifier: emailOrPhone });

          setCookies({ idToken, refreshToken, hasRecentLogin: true });
          break;
        }
        case PASSWORD_LOGIN_V2_MFA_TYPE: {
          const {
            passwordLoginV2: { mfaToken, channel, contact },
          } = data;

          setCookies(
            { mfaToken, channel, contact, hasRecentLogin: true },
            false,
          );
          window.location.replace('/two-factor');
          break;
        }
        default: {
          const userIdentifier = emailOrPhone.includes('@')
            ? 'email'
            : 'mobile number';

          trackEvent(`passwordLogin.error`, { userIdentifier: emailOrPhone });
          setErrorMessage(invalidLoginText(userIdentifier));
          setInvalidLogin(true);
          setLoginLoading(false);
        }
      }
    },
  });

  const handleThirdPartyLoginError = (
    msg = 'An error has occurred, please try again.',
  ) => {
    removeCookies();
    setThirdPartyJwt(null);
    setLoginLoading(false);

    setEmailOrPhone('');
    setInvalidLogin(true);
    setErrorMessage(msg);
  };

  const [googleJwtLogin, { loading: googleJwtLoginLoading }] = useMutation(
    GOOGLE_JWT_LOGIN,
    {
      onError(error) {
        const errorMsg = error?.graphQLErrors[0]?.message[0]?.message;
        trackEvent('authorization.login', { type: 'google error' });
        handleThirdPartyLoginError(errorMsg);
      },
      onCompleted({ googleJwtLogin: { idToken } }) {
        trackEvent('authorization.login', { type: 'google success' });

        setCookies({
          idToken,
          gToken: thirdPartyJwt.jwt,
          hasRecentLogin: true,
        });
      },
    },
  );

  const [appleJwtLogin, { loading: appleJwtLoginLoading }] = useMutation(
    APPLE_JWT_LOGIN,
    {
      onError(error) {
        const errorMsg = error?.graphQLErrors[0]?.message[0]?.message;
        trackEvent('authorization.login', { type: 'apple error' });
        handleThirdPartyLoginError(errorMsg);
      },
      onCompleted({ appleJwtLogin: { idToken } }) {
        trackEvent('authorization.login', { type: 'apple success' });

        setCookies({
          idToken,
          aToken: thirdPartyJwt.jwt,
          hasRecentLogin: true,
        });
      },
    },
  );

  async function checkUserRedirect(userIdentifier, jwt) {
    setRedirectLoading(true);
    try {
      const { data } = await client.query({
        query: USER_NEEDS_REDIRECT_QUERY,
        variables: { emailOrPhone: userIdentifier },
      });

      setRedirectLoading(false);

      if (!data) {
        trackEvent(`passwordLogin.checkUserRedirect.error`, {
          userIdentifier,
        });
        setRedirectLoading(false);
        return;
      }

      const {
        userNeedsRedirect: { needRedirect, redirectUrl, ssoEnabled },
      } = data;

      const urlParams = new URLSearchParams(window.location.search);
      const redirectURL = new URL(redirectUrl || window.location.href);

      if (urlParams.has('redirect_uri')) {
        redirectURL.searchParams.set(
          'redirect_uri',
          urlParams.get('redirect_uri'),
        );
      }

      const currentSubdomain = window.location.host.split('.')[0];
      const redirectSubdomain = redirectURL.host.split('.')[0];

      if (ssoEnabled)
        setCookies({ loggedInWithSso: true, hasRecentLogin: true });

      if (ssoEnabled && passwordInputVisible) {
        setRedirectUrl(redirectURL);
      } else if (needRedirect && ssoEnabled) {
        window.location.assign(redirectURL);
      } else if (needRedirect && currentSubdomain !== redirectSubdomain) {
        redirectURL.searchParams.set('email', userIdentifier);
        window.location.assign(redirectURL);
      } else if (jwt) {
        setLoginLoading(true);
        if (jwt.source === 'google') {
          googleJwtLogin({ variables: { input: { googleJwt: jwt.jwt } } });
        } else if (jwt.source === 'apple') {
          appleJwtLogin({ variables: { input: { appleJwt: jwt.jwt } } });
        }
      } else if (passwordInputVisible) {
        setLoginLoading(true);

        interactionAttempted();

        const captchaToken = await getRecaptchaToken();
        const identifier = formatIfPhone(userIdentifier);

        passwordLogin({
          variables: {
            input: {
              emailOrPhone: identifier,
              password,
              ...(captchaToken ? { captchaToken } : {}),
            },
          },
        });
      } else {
        setPasswordInputVisible(true);
      }
    } catch (_err) {
      setRedirectLoading(false);
      setErrorMessage(internalServerErrorMessage);
      setInvalidLogin(true);
    }
  }

  const signInWithJwt = useCallback(
    (jwt, email) => {
      setEmailOrPhone(email);
      setThirdPartyJwt(jwt);
      checkUserRedirect(email, jwt);
    },
    // eslint-disable-next-line
    [setEmailOrPhone],
  );

  const onNext = () => {
    const isValidUsername = isValidEmailOrPhone(emailOrPhone);

    setMissingUsername(!emailOrPhone);
    setInvalidUsername(!isValidUsername);

    if (passwordInputVisible) setMissingPassword(!password);

    const noPasswordSSOCheck = !passwordInputVisible && isValidUsername;
    const withPasswordSSOCheck =
      passwordInputVisible && password && isValidUsername;

    if (noPasswordSSOCheck || withPasswordSSOCheck)
      checkUserRedirect(emailOrPhone);
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) onNext();
  };

  const onPasswordKeydown = (e) => {
    setCapsLockOn(e.getModifierState('CapsLock'));
    if (e.keyCode === 13) onNext();
  };

  const onEmailOrPhoneChange = (input) => {
    if (missingUsername && input) {
      setMissingUsername(false);
      setInvalidUsername(false);
    }

    if (invalidUsername && isValidEmailOrPhone(input)) {
      setInvalidUsername(false);
    }

    if (invalidLogin) setInvalidLogin(false);

    setEmailOrPhone(input.trim());
  };

  const onPasswordChange = (input) => {
    if (missingPassword && input) setMissingPassword(false);
    if (invalidLogin) setInvalidLogin(false);
    setPassword(input);
  };

  const googleEnabled = !!GOOGLE_ACCOUNT_URI && !!GOOGLE_CLIENT_ID;
  const appleEnabled = !!APPLE_AUTH_URI && !!APPLE_CLIENT_ID;

  return (
    <>
      <HeroElement heroId="password-login">
        <ContentContainer
          ref={(appWrapperRef) => setElementDimensionsRef(appWrapperRef)}
        >
          <Logo>
            <img
              src={whiteLabelData.logoUrl || logo}
              width="50%"
              alt={whiteLabelData.logoUrl ? 'FordDirect Logo' : 'Podium Logo'}
            />
          </Logo>
          <InvalidLoginBanner
            show={invalidLogin}
            isEmail={emailOrPhone.includes('@')}
            isPassword
            message={errorMessage}
          />
          <Input>
            <FormInputContainer>
              <FormInput
                autoFocus
                id="emailOrPhoneInput"
                errorMessage={
                  (missingUsername && missingUsernameText) ||
                  (invalidUsername && invalidUsernameText)
                }
                label="Email or mobile number"
                name="username"
                onChange={(e) => onEmailOrPhoneChange(e.target.value)}
                onFocus={() => setInvalidLogin(false)}
                onKeyDown={onEnter}
                tabindex="0"
                type="text"
                value={emailOrPhone}
              />
            </FormInputContainer>
          </Input>
          {passwordInputVisible && (
            <Input>
              <FormInput
                autoFocus
                id="passwordInput"
                errorMessage={missingPassword && missingPasswordText}
                label="Password"
                onChange={(e) => onPasswordChange(e.target.value)}
                onFocus={() => setInvalidLogin(false)}
                onKeyDown={onPasswordKeydown}
                helpText={capsLockOn && 'Caps lock is on'}
                tabindex="0"
                type={showPassword ? 'text' : 'password'}
              />
              <ToggleShow>
                <ShowButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? 'Hide' : 'Show'}
                </ShowButton>
              </ToggleShow>
            </Input>
          )}
          <ButtonHolder>
            <Button
              fullwidth
              id="signInButton"
              size="large"
              width="420px"
              variant="primary"
              onClick={onNext}
              disabled={redirectLoading || loginLoading}
              isLoading={
                loading ||
                loginLoading ||
                googleJwtLoginLoading ||
                appleJwtLoginLoading
              }
              loadingText="Signing in..."
            >
              {passwordInputVisible ? 'Sign in' : 'Next'}
            </Button>
          </ButtonHolder>

          {googleEnabled && appleEnabled && <Divider />}

          <SignInWithGoogle
            onLogin={signInWithJwt}
            containerDimensions={elementDimensions}
          />
          <SignInWithApple
            onLogin={signInWithJwt}
            containerDimensions={elementDimensions}
          />
          {passwordInputVisible && whiteLabelData.disclaimerText && (
            <PolicyText>{whiteLabelData.disclaimerText}</PolicyText>
          )}
          <ForgotPasswordText>
            <Link
              id="forgotPasswordText"
              to={paths.requestCode}
              style={LINK_STYLE}
            >
              Forgot password?
            </Link>
          </ForgotPasswordText>
        </ContentContainer>
      </HeroElement>
      <Box position="fixed" bottom="0" overflow="hidden">
        <MobileAppBanner />
      </Box>
    </>
  );
}

const FormInputContainer = styled.div`
  #emailOrPhoneInput + div {
    position: absolute;
  }
`;

const ForgotPasswordText = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
`;
