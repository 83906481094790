import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, FormInput } from '@podiumhq/podium-ui';
import { useTracking, useFlag } from '@podiumhq/tracking';

import { PASSWORDLESS_LOGIN } from '../../graphql/mutations';
import { setCookies } from '../../lib/cookies';
import { formatIfPhone } from '../../lib/validation';
import paths from '../../lib/paths';

import {
  Header,
  Text,
  Input,
  ButtonHolder,
  HelpText,
  HelpTextClickable,
} from './styles';
import { ContentContainer } from '../App/styles';
import { InvalidLoginBanner } from '..';

export default function PasswordlessLogin({ emailOrPhone, onHelpClick }) {
  const [code, setCode] = useState('');
  const [invalidLogin, setInvalidLogin] = useState(false);
  const { trackEvent } = useTracking();
  const [disablePhoneFormatLoading, disablePhoneFormat] = useFlag(
    'account_structure_disable_phone_format',
  );
  const [passwordlessLogin, { loading }] = useMutation(PASSWORDLESS_LOGIN, {
    onError() {
      trackEvent('passwordlessLogin.error', { userIdentifier: emailOrPhone });
      setInvalidLogin(true);
    },
    onCompleted(data) {
      const {
        passwordlessLogin: { idToken, refreshToken },
      } = data;

      trackEvent('passwordlessLogin.success', { userIdentifier: emailOrPhone });
      setCookies({
        idToken,
        refreshToken,
        loggedInWithCode: true,
        hasRecentLogin: true,
      });
    },
  });

  const onSubmit = () => {
    const identifier =
      !disablePhoneFormatLoading && disablePhoneFormat !== 'on'
        ? formatIfPhone(emailOrPhone)
        : emailOrPhone;

    passwordlessLogin({
      variables: { input: { emailOrPhone: identifier, code } },
    });
  };

  const onCodeChange = (input) => {
    if (invalidLogin) setInvalidLogin(false);

    setCode(input.trim());
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) onSubmit();
  };

  return (
    <ContentContainer>
      {!emailOrPhone && <Redirect to={paths.requestCode} />}
      <Header>
        Headed your way...{' '}
        <span role="img" aria-label="Grinning Face With Smiling Eyes">
          😄
        </span>
      </Header>
      <Text>
        Once you receive the temporary access code, enter it below and sign in.
      </Text>
      <InvalidLoginBanner
        show={invalidLogin}
        message="Incorrect or expired code. Please try again."
      />
      <Input>
        <FormInput
          autoFocus
          id="loginCodeInput"
          placeholder="Enter code"
          name="code"
          onChange={(e) => onCodeChange(e.target.value)}
          onFocus={() => setInvalidLogin(false)}
          onKeyDown={onEnter}
          tabindex="0"
          type="text"
          value={code}
          autoComplete="off"
        />
      </Input>
      <ButtonHolder>
        <Button
          fullwidth
          id="signInButton"
          size="large"
          onClick={onSubmit}
          isLoading={loading}
          loadingText="Signing in..."
        >
          Sign in
        </Button>
      </ButtonHolder>
      <HelpText>
        <HelpTextClickable onClick={onHelpClick}>
          Need more help?
        </HelpTextClickable>
      </HelpText>
    </ContentContainer>
  );
}
