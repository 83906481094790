import React, { useState } from 'react';
import { IconX, IconLightBulb, colors } from '@podiumhq/podium-ui';
import {
  Panel,
  Header,
  HeaderTitle,
  SubTitle,
  CloseIcon,
  Body,
  PodiumTip,
  TipTitle,
  TipText,
  TipIcon,
  List,
} from './styles';

import { ExpandableDrawer } from '..';

const forgotPassword = 'forgotPassword';
const forgotUsername = 'forgotUsername';
const noAccount = 'noAccount';
const noCode = 'noCode';

export default function SidePanel({ onClose }) {
  const [expandedSections, setExpandedSections] = useState([]);

  const onSectionClick = (section) => {
    if (expandedSections.includes(section)) {
      setExpandedSections(expandedSections.filter((s) => s !== section));
    } else {
      setExpandedSections([...expandedSections, section]);
    }
  };

  return (
    <Panel>
      <Header>
        <HeaderTitle>Signing in Help Guide</HeaderTitle>
        <SubTitle>Quick tips for common questions</SubTitle>
        <CloseIcon onClick={onClose}>
          <IconX fill={colors.steel} />
        </CloseIcon>
      </Header>
      <Body>
        <ExpandableDrawer
          label="I forgot my password, how do I get logged in?"
          expanded={expandedSections.includes(forgotPassword)}
          onClick={() => onSectionClick(forgotPassword)}
        >
          You're in the right place! Enter the email or mobile number associated
          with your Podium account and we'll send you a temporary access code.
          <br />
          <br />
          Once signed in we'll give you the option to reset your password.
        </ExpandableDrawer>
        <ExpandableDrawer
          label="I don’t know which email is associated with my Podium account."
          expanded={expandedSections.includes(forgotUsername)}
          onClick={() => onSectionClick(forgotUsername)}
        >
          If you don't know which email is associated with your account you will
          need to talk to the person in charge of Podium at your work.
          <PodiumTip>
            <TipIcon>
              <IconLightBulb fill={colors.steel} />
            </TipIcon>
            <TipText>
              <TipTitle>Podium Tip</TipTitle>
              Add your mobile number to your account
              <br /> so we can text you a login code if you
              <br /> forget your password.
            </TipText>
          </PodiumTip>
        </ExpandableDrawer>
        <ExpandableDrawer
          label="Wait, I don’t think I even have a Podium account, how do I sign up?"
          expanded={expandedSections.includes(noAccount)}
          onClick={() => onSectionClick(noAccount)}
        >
          If your company has an account with Podium, you can ask the person in
          charge of Podium at your work to add you as a user. Once added, you
          will receive a welcome email and have access to sign in. (Welcome to
          Podium by the way!)
          <br />
          <br />
          If your company doesn’t have a Podium account and you’re interested in
          purchasing Podium for your company, learn more at Podium.com.
        </ExpandableDrawer>
        <ExpandableDrawer
          label="Why am I not receiving a temporary access code?"
          expanded={expandedSections.includes(noCode)}
          onClick={() => onSectionClick(noCode)}
        >
          <p>Few things you can try:</p>
          <List>
            <li>Check your emails spam folder.</li>
            <li>Try sending another access code.</li>
          </List>
        </ExpandableDrawer>
      </Body>
    </Panel>
  );
}
