import styled from 'styled-components';
import { colors } from '@podiumhq/podium-ui';

const headerHeight = 167;
const mobileScreenWidth = '768px';

export const Panel = styled.div``;

export const Header = styled.div`
  color: ${colors.mineShaft};
  display: flex;
  flex-direction: column;
  padding: 36px 24px 24px 24px;
  margin: 0 16px;
  border-bottom: 1px solid #e4e9f0;
`;

export const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 12px;
  padding-top: 48px;
`;

export const SubTitle = styled.div`
  color: ${colors.steel};
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  left: 40px;
  position: absolute;
  top: 24px;
`;

export const Body = styled.div`
  height: calc(100% - ${headerHeight}px);
  width: 100%;
  @media (max-width: ${mobileScreenWidth}) {
    height: calc(100% - ${headerHeight + 125}px);
  }
  overflow: auto;
  position: absolute;
`;

export const PodiumTip = styled.div`
  background-color: #f1f4fe;
  display: flex;
  font-size: 14px;
  color: ${colors.steel};
  border-radius: 8px;
  padding: 24px 24px 28px 24px;
  line-height: 20px;
  margin-top: 24px;
  margin-right: -55px;
`;

export const TipIcon = styled.div`
  display: flex;
  padding-right: 16px;
`;

export const TipText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TipTitle = styled.div`
  font-size: 14px;
  padding-bottom: 4px;
  font-weight: 600;
`;

export const List = styled.ul`
  padding-left: 16px;
`;
