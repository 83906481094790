import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { performRedirect } from '../redirect';
import { USE_SECURE_COOKIES } from '../env';

const COOKIE_EXPIRATION = 365 * 20;

function getCookieDomain() {
  const domain = window.location.hostname.replace(/[^.]+\./, '');
  return domain === 'localhost' ? domain : `.${domain}`;
}

const cookies = Cookies.withAttributes({
  domain: getCookieDomain(),
});

function isChromeApp() {
  const url = window.location.search;
  const chromeAppRegex = /http[s]?:\/\/\w+\.(chromiumapp.org)\/.*/;

  return chromeAppRegex.test(url);
}

function handleChromeAppRedirect(idToken, refreshToken) {
  const extractUrlRegex = /\?state=(.*)/;
  const refUrl = window.location.search.match(extractUrlRegex)[1];

  // If they've already authenticated, `refreshToken` will be null,
  // and we won't put it on the query string.
  const refreshString = refreshToken ? `&refreshToken=${refreshToken}` : '';

  window.location.href = `${refUrl}?idToken=${idToken}${refreshString}`;
}

export function setCookies(cookieValues, shouldPerformRedirect = true) {
  const {
    aToken,
    gToken,
    idToken,
    loggedInWithCode,
    loggedInWithSso,
    refreshToken,
    mfaToken,
    channel,
    contact,
    hasRecentLogin,
  } = cookieValues;

  if (idToken) {
    const { exp } = jwtDecode(idToken);
    const expiration = new Date(exp * 1000);
    cookies.set('idToken', idToken, {
      expires: expiration,
      secure: USE_SECURE_COOKIES,
      sameSite: 'lax',
    });
  }

  if (refreshToken) {
    cookies.set('refreshToken', refreshToken, {
      expires: COOKIE_EXPIRATION,
      secure: USE_SECURE_COOKIES,
      sameSite: 'lax',
    });
  }

  if (aToken) {
    cookies.set('aToken', aToken, {
      expires: COOKIE_EXPIRATION,
      secure: USE_SECURE_COOKIES,
      sameSite: 'lax',
    });
  }

  if (gToken) {
    cookies.set('gToken', gToken, {
      expires: COOKIE_EXPIRATION,
      secure: USE_SECURE_COOKIES,
      sameSite: 'lax',
    });
  }

  if (loggedInWithCode) {
    cookies.set('loggedInWithCode', loggedInWithCode, {
      expires: COOKIE_EXPIRATION,
    });
  }

  if (loggedInWithSso) {
    cookies.set('loggedInWithSso', loggedInWithSso, {
      expires: COOKIE_EXPIRATION,
    });
  }

  if (channel) {
    cookies.set('channel', channel, {
      expires: COOKIE_EXPIRATION,
    });
  }

  if (contact) {
    cookies.set('contact', contact, {
      expires: COOKIE_EXPIRATION,
    });
  }

  if (mfaToken) {
    cookies.set('mfaToken', mfaToken, {
      expires: COOKIE_EXPIRATION,
      secure: USE_SECURE_COOKIES,
      sameSite: 'strict',
    });
  }

  if (hasRecentLogin) {
    const EXPIRATION = 90; // days
    cookies.set('hasRecentLogin', hasRecentLogin, {
      expires: EXPIRATION,
      sameSite: 'lax',
    });
  }

  if (isChromeApp()) {
    // If the redirect is from <extension_id>.chromiumapp.org/,
    // append the idToken and refreshToken as params to the url.
    // Then redirect them back to the extension
    handleChromeAppRedirect(idToken, refreshToken);
  } else if (shouldPerformRedirect) {
    performRedirect();
  }
}

export function hasAuthToken() {
  const idToken = cookies.get('idToken');
  const refreshToken = cookies.get('refreshToken');

  return Boolean(idToken || refreshToken);
}

export function hasTwoFactorJwt() {
  // Should also check that it is legit
  const mfaToken = cookies.get('mfaToken');
  return Boolean(mfaToken);
}

export function getCookie(cookieKey) {
  return cookies.get(cookieKey);
}

// We only want to remove the gToken cookie if the user manually logged out.
// If they manually log out, then the token should still be valid. Otherwise,
// this was the result of the idToken expiring. In which case, leave the gToken
function removeGtokenIfValid() {
  try {
    const gToken = cookies.get('gToken');
    if (gToken) {
      const decodedJwt = jwtDecode(gToken);
      const unixNow = Math.floor(Date.now() / 1000);
      if (decodedJwt.exp > unixNow) {
        cookies.remove('gToken');
      }
    }
  } catch (_) {
    cookies.remove('gToken');
  }
}

export function removeCookies() {
  cookies.remove('idToken');
  cookies.remove('refreshToken');
  cookies.remove('mfaToken');
  cookies.remove('mockIdToken');
  cookies.remove('mockToken');
  cookies.remove('aToken');
  removeGtokenIfValid();
}

export function unsnoozeLoginPrompts() {
  cookies.remove('snoozePhonePrompt');
}

export default cookies;
