import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, InMemoryCache } from '@apollo/client';
import ApolloClient from 'apollo-boost';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@podiumhq/design-system';

import './index.css';
import { App } from './components';
import { fingerprintHeaders } from './lib/fingerprint';
import { STORMCROW_HOST } from './lib/env';
import initDatadog from './lib/third-party-scripts/initDatadog';

initDatadog();

const cache = new InMemoryCache({
  possibleTypes: {
    OauthPreauthorizeData: ['oauthPreauthorizeData', 'oauthRedirect'],
  },
});

const headers = fingerprintHeaders();

const client = new ApolloClient({
  uri: `${STORMCROW_HOST}/graphql`,
  headers,
  cache,
});

function Root() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Router>
    </ApolloProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
